import React from "react"
import ProductPage from "../../layouts/product-page"

const model = {
  eng: {
    id: "gc176",
    model: "Headphones GC-176",
    speakers: "40mm Dynamic Headphone Speaker",
    sensitivity: "105dB+/-3dB",
    "frequency-range": "20Hz-20,000Hz",
    "max-input-power": "100mW",
    cable: "1.2m with 3.5mm audio jack",
    battery: "n/a",
    color: "black",
    accessories: "default: microphone",
    image:
      "https://lh3.googleusercontent.com/pw/AL9nZEXlVEArwlGmltdyc8it4fjEXELP1BvtGutEuh7FKrR5v0wijyUL5bLw7o5g0TvDOoH_rh8CncWN6UP00SegJzrIHTFkURQlxw-rsO1iqVdIwujF_h3q40fvDY9ts_aZ1eHAG28Sj5Ad0xfJTp00Mhkt=d",
  },
  esp: {
    id: "gc176",
    model: "Audífonos GC-176",
    bocinas: "40mm tipo dinámico",
    sensibilidad: "105dB+/-3dB",
    "Repuesta en Frencuencia": "20Hz-20,000Hz",
    "Potencia de Entrada Máxima": "100mW",
    "Longitud de Cable": "1.2m",
    "Tipo de Conector": "3.5mm estéreo",
    batería: "n/a",
    color: "negro",
    accesorios: "con micrófono",
    image:
      "https://lh3.googleusercontent.com/pw/AL9nZEXlVEArwlGmltdyc8it4fjEXELP1BvtGutEuh7FKrR5v0wijyUL5bLw7o5g0TvDOoH_rh8CncWN6UP00SegJzrIHTFkURQlxw-rsO1iqVdIwujF_h3q40fvDY9ts_aZ1eHAG28Sj5Ad0xfJTp00Mhkt=d",
  },
}

export default () => <ProductPage model={model} />
